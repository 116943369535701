/** FlatUI Color Palette
*
*	Color names based on FlatUI: https://flatuicolors.com/
*/

export default {
  turquoise: '#1abc9c',
  greenSea: '#16a085',
  emerald: '#2ecc71',
  nephritis: '#27ae60',
  peterRiver: '#3498db',
  belizeHole: '#2980b9',
  amethyst: '#9b59b6',
  wisteria: '#8e44ad',
  wetAsphalt: '#34495e',
  midnightBlue: '#2c3e50',
  sunflower: '#f1c40f',
  orange: '#f39c12',
  carrot: '#e67e22',
  pumpkin: '#d35400',
  alizarin: '#e74c3c',
  pomegranite: '#c0392b',
  clouds: '#ecf0f1',
  silver: '#bdc3c7',
  concrete: '#95a5a6',
  asbestos: '#7f8c8d',

  lightGreenishBlue: '#55efc4',
  mintLeaf: '#00b894',
  sourLemon: '#ffeaa7',
  brightYarrow: '#fdcb6e',
  fadedPoster: '#81ecec',
  robinsEggBlue: '#00cec9',
  peachTea: '#fab1a0',
  orangeville: '#e17055',
  greenDarnerTrail: '#74b9ff',
  electronBlue: '#0984e3',
  pinkGlamour: '#ff7675',
  candyApple: '#d63031',
  shyMoment: '#a29bfe',
  exodusFruit: '#6c5ce7',
  picoPink: '#fd79a8',
  prunusAvium: '#e84393',
  cityLights: '#dfe6e9',
  soothingBreeze: '#b2bec3',
  americanRiver: '#636e72',
  draculaOrchid: '#2d3436',
};
