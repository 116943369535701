// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var List = require("bs-platform/lib/js/list.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Jss = require("styles/jss");
var Option$CourseEditor = require("../../utils/reason/Option.bs.js");

function classNames(names) {
  return List.fold_left((function (a, b) {
                return a + (" " + b);
              }), "", List.filter((function (name) {
                      return name !== "";
                    }))(names));
}

function jssClass(classes, name) {
  return Option$CourseEditor.valueOr(Js_dict.get(classes, name), "");
}

function injectSheet(prim, prim$1) {
  return Jss.injectSheetRE(prim, prim$1);
}

exports.classNames = classNames;
exports.jssClass = jssClass;
exports.injectSheet = injectSheet;
/* styles/jss Not a pure module */
