// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


function strEl(prim) {
  return prim;
}

exports.strEl = strEl;
/* No side effect */
