export enum DiscoverableId {
  CodeBlockEditorHighlighting,
  CodeBlockEditorLineNumbers,
  CodeBlockEditorLanguage,
  CustomEditorDetails,
  DlEditorTitle,
  DtEditorTitle,
  DdEditorTitle,
  ReplEditorDetails,
  OrderedListEditorTitle,
  OrderedListEditorStyle,
  OrderedListEditorStart,
  UnorderedListEditorTitle,
  UnorderedListEditorStyle,
  TableEditorTitle,
  TableEditorRowStyle,
  YouTubeEditorSourceURL,
  IFrameEditorWebpageURL,
  DefinitionEditorTitle,
  AlternativeEditorKey,
  AlternativesEditorGroup,
  AlternativesEditorDefault,
}
