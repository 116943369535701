import { JSSStyles } from 'styles/jss';

export const styles: JSSStyles = {
  alternatives: {

  },
  alternative: {
    marginTop: '20px',
  },
};
