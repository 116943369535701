import { JSSStyles } from 'styles/jss';

export const styles: JSSStyles = {
  entryLabel: {
    color: '#4586ef',
    fontSize: '12pt',
    textTransform: 'uppercase',
  },
  entry: {

  },
};
