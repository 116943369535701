
import * as ct from './contentTypes';
import { registerType } from './content/common/parse';

export function registerContentTypes() {
  registerType('activity', ct.Activity.fromPersistence);
  registerType('activity_link', ct.ActivityLink.fromPersistence);
  registerType('activity_report', ct.ActivityReport.fromPersistence);
  registerType('alternate', ct.Alternate.fromPersistence);
  registerType('alternative', ct.Alternative.fromPersistence);
  registerType('alternatives', ct.Alternatives.fromPersistence);
  registerType('anchor', ct.Anchor.fromPersistence);
  registerType('answer', ct.Answer.fromPersistence);
  registerType('applet', ct.Applet.fromPersistence);
  registerType('audio', ct.Audio.fromPersistence);
  registerType('caption', ct.Caption.fromPersistence);
  registerType('cite', ct.Cite.fromPersistence);
  registerType('choice', ct.FeedbackChoice.fromPersistence);
  registerType('code', ct.BlockCode.fromPersistence);
  registerType('codeblock', ct.CodeBlock.fromPersistence);
  registerType('command', ct.Command.fromPersistence);
  registerType('composite_activity', ct.Composite.fromPersistence);
  registerType('conjugate', ct.Conjugate.fromPersistence);
  registerType('conjugation', ct.Conjugation.fromPersistence);
  registerType('cr', ct.Cr.fromPersistence);
  registerType('custom', ct.Custom.fromPersistence);
  registerType('dd', ct.Dd.fromPersistence);
  registerType('default', ct.Default.fromPersistence);
  registerType('definition', ct.Definition.fromPersistence);
  registerType('dialog', ct.Dialog.fromPersistence);
  registerType('director', ct.Director.fromPersistence);
  registerType('dl', ct.Dl.fromPersistence);
  registerType('dt', ct.Dt.fromPersistence);
  registerType('example', ct.Example.fromPersistence);
  registerType('extra', ct.Extra.fromPersistence);
  registerType('figure', ct.Figure.fromPersistence);
  registerType('flash', ct.Flash.fromPersistence);
  registerType('formula', ct.BlockFormula.fromPersistence);
  registerType('iframe', ct.IFrame.fromPersistence);
  registerType('image', ct.Image.fromPersistence);
  registerType('input_ref', ct.InputRef.fromPersistence);
  registerType('inquiry', ct.Inquiry.fromPersistence);
  registerType('instructions', ct.Instructions.fromPersistence);
  registerType('li', ct.Li.fromPersistence);
  registerType('line', ct.Line.fromPersistence);
  registerType('link', ct.Link.fromPersistence);
  registerType('material', ct.Material.fromPersistence);
  registerType('materials', ct.Materials.fromPersistence);
  registerType('math', ct.Math.fromPersistence);
  registerType('#math', ct.Math.fromPersistence);
  registerType('m:math', ct.Math.fromPersistence);
  registerType('mathematica', ct.Mathematica.fromPersistence);
  registerType('meaning', ct.Meaning.fromPersistence);
  registerType('multipanel', ct.Multipanel.fromPersistence);
  registerType('objref', ct.ObjRef.fromPersistence);
  registerType('ol', ct.Ol.fromPersistence);
  registerType('panopto', ct.Panopto.fromPersistence);
  registerType('param', ct.Param.fromPersistence);
  registerType('popout', ct.Popout.fromPersistence);
  registerType('pref:label', ct.PrefLabel.fromPersistence);
  registerType('pref:value', ct.PrefValue.fromPersistence);
  registerType('pronunciation', ct.Pronunciation.fromPersistence);
  registerType('proof', ct.Proof.fromPersistence);
  registerType('pullout', ct.Pullout.fromPersistence);
  registerType('question', ct.InquiryQuestion.fromPersistence);
  registerType('quote', ct.BlockQuote.fromPersistence);
  registerType('section', ct.WorkbookSection.fromPersistence);
  registerType('source', ct.Source.fromPersistence);
  registerType('speaker', ct.Speaker.fromPersistence);
  registerType('statement', ct.Statement.fromPersistence);
  registerType('sym', ct.Sym.fromPersistence);
  registerType('table', ct.Table.fromPersistence);
  registerType('td', ct.CellData.fromPersistence);
  registerType('th', ct.CellHeader.fromPersistence);
  registerType('theorem', ct.Theorem.fromPersistence);
  registerType('title', ct.Title.fromPersistence);
  registerType('tr', ct.Row.fromPersistence);
  registerType('track', ct.Track.fromPersistence);
  registerType('translation', ct.Translation.fromPersistence);
  registerType('ul', ct.Ul.fromPersistence);
  registerType('unity', ct.Unity.fromPersistence);
  registerType('video', ct.Video.fromPersistence);
  registerType('wb:inline', ct.WbInline.fromPersistence);
  registerType('xref', ct.Xref.fromPersistence);
  registerType('youtube', ct.YouTube.fromPersistence);
}
